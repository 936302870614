<script>
  import {createEventDispatcher} from "svelte";

  const dispatch = createEventDispatcher();
  export let header = "";

  function closeModal(result) {
    dispatch("close", {result: result});
  }
</script>

<style>
  .documents-dialog__header {
    font-size: 22px;
    color: #333333;
    font-family: "Roboto";
    font-weight: 400;
    text-align: center;
  }

  .documents-dialog__content {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    font-family: "Roboto";
    font-weight: 400;
  }

  .popup-block_documents {
    width: 1043px;
    height: 682px;
    max-height: calc(100vh - 30px);
  }
</style>

<div class="overlay" style="visibility: visible" on:click="{() => closeModal('close')}">
  <div class="popup-block popup-block_documents">
    <header>
      <h2 class="documents-dialog__header">{header}</h2>
    </header>
    <main class="documents-dialog__content">
      <slot/>
    </main>
    <button class="popup-block__close reset-button" on:click="{() => closeModal('close')}"/>
  </div>
</div>
