<script>
  import DocumentsDialog from './DocumentsDialog.svelte';
  import {BASE_API_URL} from "./api-config";

  function getImgUrl(smallPicture) {
    return smallPicture ? smallPicture : 'landstatic/assets/images/no-photo.png';
  }

  function showDialog(id) {
    currentBonusId = id;
    showDocumentDialog = true;
  }

  const buttonsNames = ['хочу быть первым', 'Буду лучшим', 'я хочу участвовать'];
  let bonusPrograms = [];
  let currentBonusId;
  let showDocumentDialog = false;

  fetch(`${BASE_API_URL}/tbm-bonus/public/programs`)
      .then(response => response.json())
      .then(programs => {
        bonusPrograms = breaksIntoLines(programs, 3);
      });

  function breaksIntoLines(arr, size) {
    let result = [];
    for (let i = 0; i < Math.ceil(arr.length / size); i++) {
      result[i] = arr.slice((i * size), (i * size) + size);
    }
    return result;
  }
</script>

<style>
  .current-bonuses__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 47px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .current-bonuses__items__item {
    width: 320px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .current-bonuses__items__item__title {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .current-bonuses__items__item__title h3 {
    width: 100%;
    color: #333333;
    text-align: center;
    font-family: "Calibri";
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
  }

  .current-bonuses__items__item__img {
    max-width: 100%;
    object-fit: cover;
    height: inherit;
  }

  .current-bonuses__items__item__img-container {
    position: relative;
    width: 324px;
    height: 228px;
    background-color: #ffffff;
    border-radius: 12px;
    border: 2px solid #ffc300;
    overflow: hidden;
  }

  .current-bonuses__items__item__img-container:after {
    content: "";
    position: absolute;
    bottom: -62px;
    left: 0;
    width: 100%;
    height: 141px;
    background-image: url(../assets/images/elipse-shadow.png);
    z-index: -1;
  }

  .current-bonuses__items__item__link-btn {
    margin-top: 34px;
    font-size: 18px;
    color: #333333;
    font-family: "Calibri";
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
  }

  .current-bonuses__items__item__btn {
    margin: auto;
    margin-top: 24px;
  }

  .documents-iframe {
    height: 550px;
    max-height: calc(100vh - 150px);
  }
</style>

<h2 class="section-title section-title_color_dark">
  Действующие бонусные программы:
</h2>
{#each bonusPrograms as rowPrograms, i}
  <ul class="current-bonuses__items reset-list">
    {#each rowPrograms as { programName, smallPicture, id }, i}
      <li class="current-bonuses__items__item">
        <div class="current-bonuses__items__item__title">
          <h3>{programName}</h3>
        </div>
        <div class="current-bonuses__items__item__img-container">
          <img
              src="{getImgUrl(smallPicture)}"
              alt=""
              class="current-bonuses__items__item__img"/>
        </div>
        <button
            class="current-bonuses__items__item__link-btn reset-button"
            on:click={() => showDialog(id)}>Ознакомиться с правилами
        </button>
        <button
            class="current-bonuses__items__item__btn round-button round-button_cian reset-button"
            on:click={window.showOverlay}>
          {buttonsNames[i]}
        </button>
      </li>
    {/each}
<!--Нужно для разметки последней неполной строки-->
    {#if rowPrograms.length === 2}
      <li class="current-bonuses__items__item"></li>
    {/if}
  </ul>
{/each}
{#if showDocumentDialog}
  <DocumentsDialog header={'Правила и документы'} on:close="{() => showDocumentDialog = false}">
    <iframe
        title="'Правила и документы"
        class="documents-iframe"
        src="{`${BASE_API_URL}/tbm-bonus/documents/rules/${currentBonusId}.html`}"
        frameborder="0"
        width="100%"
    ></iframe>
  </DocumentsDialog>
{/if}
